<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as DOCUMENT_CONFIG_TYPES } from '../store'

export default {
  name: 'OrganizationCategoryMixin',
  created () {
    if (!this.organizationCategoryChoices || (this.organizationCategoryChoices && this.organizationCategoryChoices.length === 0)) {
      this.getOrganizationcategorychoices()
    }
  },
  computed: {
    ...mapGetters({
      organizationCategoryChoices: DOCUMENT_CONFIG_TYPES.GENERIC.document.documentconfig.LIST.GETTERS.organizationCategoryChoices
    }),
    ownerCategoryOptions () {
      if (this.organizationCategoryChoices) {
        return this.prepareChoiceListForSelect(this.organizationCategoryChoices, 0, 1)
      }
      return []
    }
  },
  methods: {
    ...mapMutations({
      setOrganizationCategoryChoices: DOCUMENT_CONFIG_TYPES.GENERIC.document.documentconfig.LIST.MUTATIONS.setOrganizationCategoryChoices
    }),
    ...mapActions({
      getOrganizationcategorychoices: DOCUMENT_CONFIG_TYPES.GENERIC.document.documentconfig.LIST.ACTIONS.getOrganizationcategorychoices
    }),
    clearOwnerCategory () {
      this.$set(this.form, 'owner_category', null)
      this.$set(this.form, 'owner_category_name', null)
    },
    selectOwnerCategory (item) {
      this.$set(this.form, 'owner_category', item.value)
      this.$set(this.form, 'owner_category_name', item.name)
    }
  }
}
</script>
