<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as DOCUMENT_CONFIG_TYPES } from '../store'
import { vue } from '../../../../main'

export default {
  name: 'ProductFormMixin',
  created () {
    this.setProductList({})
    if (this.form.product) {
      this.setProductList({})
    }
  },
  computed: {
    ...mapGetters({
      productList: DOCUMENT_CONFIG_TYPES.GENERIC.document.documentconfig.LIST.GETTERS.productList
    }),
    productListOptions () {
      if (this.productList) {
        return this.prepareListForMultiSelect(this.productList, 'id', ['name'])
      }
      return []
    }
  },
  methods: {
    ...mapMutations({
      setProductList: DOCUMENT_CONFIG_TYPES.GENERIC.document.documentconfig.LIST.ACTIONS.setProductlist
    }),
    ...mapActions({
      getProductList: DOCUMENT_CONFIG_TYPES.GENERIC.document.documentconfig.LIST.ACTIONS.getProductlist
    }),
    selectProduct (item) {
      this.$set(this.form, 'product', item.id)
      this.$set(this.form, 'product_name', item.name)
    },
    clearProduct () {
      this.$set(this.form, 'product', null)
      this.$set(this.form, 'product_name', null)
    },
    onSearchProduct (search) {
      vue.$store.commit('componentLoading', false)
      if (search.length >= 2) {
        const filters = {}
        filters.filter__name__icontains = search
        filters.order_by = '-status'
        this.getProductList(filters).then(() => {
          vue.$store.commit('componentLoading', true)
        })
      } else {
        this.setProductList({})
        vue.$store.commit('componentLoading', true)
      }
    }
  }
}
</script>
