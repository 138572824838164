<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as DOCUMENT_CONFIG_TYPES } from '../store'
import { vue } from '@/main'

export default {
  name: 'OrganizationFormMixin',
  created () {
    this.setOrganizationFormList([])
  },
  computed: {
    ...mapGetters({
      organizationFormList: DOCUMENT_CONFIG_TYPES.GENERIC.document.documentconfig.LIST.GETTERS.organizationFormList
    }),
    organizationFormListOptions () {
      if (this.organizationFormList) {
        return this.prepareListForMultiSelect(this.organizationFormList, 'id', ['name'])
      }
      return []
    }
  },
  methods: {
    ...mapMutations({
      setOrganizationFormList: DOCUMENT_CONFIG_TYPES.GENERIC.document.documentconfig.LIST.MUTATIONS.setOrganizationformlist
    }),
    ...mapActions({
      getOrganizationformlist: DOCUMENT_CONFIG_TYPES.GENERIC.document.documentconfig.LIST.ACTIONS.getOrganizationformlist
    }),
    onSearchOrganizationForm (search) {
      vue.$store.commit('componentLoading', false)
      if (search.length >= 2) {
        const filters = {}
        filters.filter__name__icontains = search
        filters.order_by = '-status'
        this.getOrganizationformlist(filters).then(() => {
          vue.$store.commit('componentLoading', true)
        })
      } else {
        this.setOrganizationFormList([])
        vue.$store.commit('componentLoading', true)
      }
    },
    getOrganizationForm (formData) {
      const values = []
      if (formData.owner_forms) {
        this.organizationFormListOptions.forEach(element => {
          if (formData.owner_forms.includes(element.id)) {
            values.push({
              id: element.id,
              name: element.name,
              status: element.status
            })
          }
        })
        return values
      }
    },
    setOrganizationForm (formData) {
      if (this.form.owner_forms) {
        const list = []
        this.form.owner_forms.forEach((row) => list.push(row.id))
        this.$set(formData, 'owner_forms', list)
      } else {
        this.$set(formData, 'owner_forms', [])
      }
      return formData
    },
    removeOrganizationForm (item) {
      const orgForm = this.form.owner_forms.filter((orgForm) => {
        return orgForm.id !== item.id
      })
      this.$set(this.form, 'owner_forms', orgForm)
      if (this.form.owner_forms.length === 0) this.$set(this.form, 'owner_forms', null)
    },
    selectOrganizationForm (item) {
      if (!this.form.owner_forms) {
        this.$set(this.form, 'owner_forms', [])
      }
      this.form.owner_forms.push(item)
    }
  }
}
</script>
