<template>
  <panel :title="title" noButton="true" v-has-perms="rolePerm">
    <div class="note note-light-primary">
      <div class="note-content">
        <h4><b>{{"INSTRUCTIONS" | translate}}</b></h4>
        <p>
        <ul>
          <li>
            <strong>{{'Date start-end equired' | translate}}</strong>:  {{'if selected, it indicates that the document to be uploaded must have validity start and end dates' | translate }}
          </li>
          <li>
            <strong>{{'Is Downloadable' | translate}}</strong>:  {{"if selected, it indicates that the document has an associated 'basic' copy that can be downloaded from the list of documents, compiled and reloaded (Eg PSC)" | translate }}
          </li>
          <li>
            <strong>{{'Generated from template' | translate}}</strong>:  {{"if selected, it indicates that the document has an associated 'basic' copy which is generated by the system starting from an ODT and can be downloaded from the list of documents, filled in and reloaded (Ex. Nomine)" | translate }}
          </li>
          <li>
            <strong>{{'Is Optional' | translate}}</strong>:  {{"if selected, it indicates that the document is sent as optional for the site in which it is requested" | translate }}
          </li>
          <li>
            <strong>{{'Any organization' | translate}}</strong>:  {{"if selected, it indicates that the document is required for all companies working on the construction site, regardless of the other configuration parameters" | translate }}
          </li>
          <li>
            <strong>{{'Requested to Pidueci' | translate}}</strong>:  {{"if selected, it indicates that Pidueci is responsible for providing and uploading a copy of the document" | translate }}
          </li>
          <li>
            <strong>{{'Organization form' | translate}}</strong>:  {{"if selected, it indicates that the request to upload the document is made to all companies with the indicated corporate form. If not selected, the parameter is ignored. To indicate more company forms, a configuration is required for each desired form" | translate }}
          </li>
          <li>
            <strong>{{'Category' | translate}}</strong>:  {{"if selected, it indicates that the request to upload the document is made to all companies with the indicated category. If not selected, the parameter is ignored. To indicate categories, a configuration is required for each desired category" | translate }}
          </li>
          <li>
            <strong>{{'Any yard' | translate}}</strong>:  {{"if selected, it indicates that a new copy of the document must be uploaded for each construction site (if the company participates in two construction sites, it must upload two separate documents)" | translate }}
          </li>
          <li>
            <strong>{{'Product' | translate}}</strong>:  {{"if selected, it indicates that the loading request is generated or not based on the products in the quote. To indicate more products, it is necessary to create a configuration for each desired product" | translate }}
          </li>
        </ul>
       </p>
      </div>
    </div>
    <div>
      <b-form>
        <b-form-row>
          <form-thux-horizontal-input
            :validator="$v.form.description"
            :external-errors="errors['description']"
            :label-form="'Configuration name' | translate"
            class-form="col-12 mb-2"
            type="text"
            :value="form.description"
            :disabled="!formEditable"
            @change="$set(form, 'description', $event)"
          />
        </b-form-row>
        <hr/>
        <b-form-row>
          <form-thux-horizontal-checkbox
            :validator="$v.form.date_start_end"
            :external-errors="errors['date_start_end']"
            :label-form="'Date start-end equired' | translate"
            class-form="col-3 mb-2"
            label-cols-lg="4"
            label-cols-sm="4"
            :value="form.date_start_end"
            :disabled="!formEditable"
            @change="$set(form, 'date_start_end', $event)"
          />
          <form-thux-horizontal-checkbox
            :validator="$v.form.is_downloadable"
            :external-errors="errors['is_downloadable']"
            :label-form="'Is Downloadable' | translate"
            class-form="col-3 mb-2"
            label-cols-lg="3"
            label-cols-sm="3"
            :value="form.is_downloadable"
            :disabled="!formEditable"
            @change="$set(form, 'is_downloadable', $event)"
          />
          <form-thux-horizontal-checkbox
            :validator="$v.form.is_created_by_template"
            :external-errors="errors['is_created_by_template']"
            :label-form="'Generated from template' | translate"
            class-form="col-3 mb-2"
            label-cols-lg="3"
            label-cols-sm="3"
            :value="form.is_created_by_template"
            :disabled="!formEditable"
            @change="$set(form, 'is_created_by_template', $event)"
          />
          <form-thux-horizontal-checkbox
            :validator="$v.form.is_optional"
            :external-errors="errors['is_optional']"
            :label-form="'Is Optional' | translate"
            class-form="col-3 mb-2"
            label-cols-lg="3"
            label-cols-sm="3"
            :value="form.is_optional"
            :disabled="!formEditable"
            @change="$set(form, 'is_optional', $event)"
          />
        </b-form-row>
        <hr/>
        <b-form-row>
          <form-thux-horizontal-checkbox
            :validator="$v.form.any_organization"
            :external-errors="errors['any_organization']"
            :label-form="'Any organization' | translate"
            class-form="col-3 mb-2"
            label-cols-lg="3"
            label-cols-sm="3"
            :value="form.any_organization"
            :disabled="!formEditable"
            @change="$set(form, 'any_organization', $event)"
          />
          <form-thux-horizontal-checkbox
            :validator="$v.form.owner_organization"
            :external-errors="errors['owner_organization']"
            :label-form="'Requested to Pidueci' | translate"
            class-form="col-3 mb-2"
            label-cols-lg="3"
            label-cols-sm="3"
            :value="form.owner_organization"
            :disabled="!formEditable"
            @change="$set(form, 'owner_organization', $event)"
          />
          <form-thux-horizontal-multiselect
            :validator="$v.form.owner_forms"
            :external-errors="errors['owner_forms']"
            :label-form="'Organization form' | translate"
            class-form="col-lg-3 mb-2"
            labelColsSm="3"
            labelColsLg="3"
            :disabled="!formEditable"
            label-search="name"
            :value="form.owner_forms"
            :placeholder="
              $t('Select an organization form')
            "
            :options="organizationFormListOptions"
            @search="onSearchOrganizationForm"
            @remove="removeOrganizationForm"
            @select="selectOrganizationForm"
            @clear="$set(form, 'organization_forms', null)"
          />
          <form-thux-horizontal-select
            :validator="$v.form.owner_category"
            :external-errors="errors['owner_category']"
            :label-form="'Category' | translate"
            class-form="col-lg-3 mb-2"
            labelColsSm="3"
            labelColsLg="3"
            :disabled="!formEditable"
            :label-search="'name'"
            :value="form.owner_category ? { value: form.owner_category, name: form.owner_category_name } : null"
            :options="ownerCategoryOptions"
            :placeholder="$t('Select a category')"
            @select="selectOwnerCategory"
            @clear="clearOwnerCategory"
          />
        </b-form-row>
        <hr/>
        <b-form-row>
          <form-thux-horizontal-checkbox
            :validator="$v.form.any_yard"
            :external-errors="errors['any_yard']"
            :label-form="'Any yard' | translate"
            class-form="col-4 mb-2"
            label-cols-lg="3"
            label-cols-sm="3"
            :value="form.any_yard"
            :disabled="!formEditable"
            @change="$set(form, 'any_yard', $event)"
          />
          <form-thux-horizontal-autocomplete
            :validator="$v.form.product"
            :external-errors="errors['product']"
            :label-form="'Product' | translate"
            class-form="col-lg-6 mb-3"
            :disabled="!formEditable"
            :customize-option="true"
            labelColsSm="4"
            labelColsLg="2"
            :label-search="'name'"
            :value="form.product ? { id: form.product, name: form.product_name } : null"
            :options="productListOptions"
            :placeholder="$t('Type a product')"
            :placeholder-field="form.product_name"
            @select="selectProduct"
            @search="onSearchProduct"
            @clear="clearProduct">
          </form-thux-horizontal-autocomplete>
        </b-form-row>
      </b-form>
      <div class="mt-3 f-w-600 d-flex justify-content-end">
        <div class="mt-3 f-w-600 d-flex justify-content-end">
          <b-button
            class="mr-3"
            variant="default"
            @click.prevent="formEditable ? cancel() : closeForm()">
            {{ formEditable ? 'Cancel' : 'Close' | translate }}
          </b-button>
          <b-button
            variant="primary"
            v-has-perms="editRolePerm"
            @click.prevent="formEditable ? onSubmit() : makeFormEditable()"
            :disabled="$v.form.$invalid">
            {{ formEditable ? 'Save' : 'Edit' | translate }}
          </b-button>
        </div>
      </div>
    </div>
  </panel>
</template>

<script>
// import { required } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'
import { TYPES as DOCUMENT_CONFIG_TYPES } from './store'
import { TYPES as DOCUMENT_TYPES } from '../document/store'

import ThuxDetailPanelMixin from '@/components/thux-detail-panel/ThuxDetailPanelMixin'
import OrganizationFormMixin from './mixins/OrganizationFormMixin'
import OrganizationCategoryMixin from './mixins/OrganizationCategoryMixin'
import ProductFormMixin from './mixins/ProductFormMixin'

export default {
  name: 'DocumentConfigEdit',
  mixins: [
    ThuxDetailPanelMixin,
    OrganizationFormMixin,
    ProductFormMixin,
    OrganizationCategoryMixin
  ],
  data () {
    return {
      form: {
        owner_forms: []
      },
      pageName: 'Document config',
      rolePerm: 'document_documentconfig_retrieve',
      editRolePerm: 'document_documentconfig_update'
    }
  },
  computed: {
    ...mapGetters({
      ...DOCUMENT_CONFIG_TYPES.GENERIC.document.documentconfig.DETAIL.GETTERS
    })
  },
  components: { },
  methods: {
    ...mapActions({
      ...DOCUMENT_CONFIG_TYPES.GENERIC.document.documentconfig.DETAIL.ACTIONS,
      getDocumentList: DOCUMENT_TYPES.GENERIC.document.document.LIST.ACTIONS.getList
    }),
    getFormData () {
      const formData = Object.assign({}, this.form)
      formData.document = this.$route.params.docId
      return formData
    },
    onSubmit () {
      const formData = this.getFormData()
      const promise = this.id ? this.update(formData) : this.create(formData)
      promise.then(
        () => {
          setTimeout(() => {
            if (!this.id) {
              this.closeForm()
            } else {
              this.makeFormReadonly()
            }
            this.getDocumentList()
          }, 500)
        }
      )
    }
  },
  validations: {
    form: {
      description: { },
      date_start_end: { },
      is_downloadable: { },
      is_created_by_template: {},
      is_optional: { },
      owner_form: { },
      product: { },
      any_yard: { },
      any_organization: { },
      owner_organization: { }
    }
  }
}
</script>
