
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as DOCUMENT_CONFIG_TYPES } from './store'
import { TYPES as DOCUMENT_TYPES } from '../document/store'
import { ROUTES as DOCUMENT_ROUTES } from '../document/router'
import { hasPerm } from '@/components/PermissionHelper'

import ThuxListMixin from '@/components/thux-list/ThuxListMixin'

import DocumentConfigEdit from './DocumentConfigEdit'
import DocumentConfigTable from './DocumentConfigTable'

export default {
  name: 'DocumentConfigList',
  mixins: [ThuxListMixin],
  components: {
    'component-table': DocumentConfigTable,
    'component-edit': DocumentConfigEdit,
    'component-detail': DocumentConfigEdit
  },
  data () {
    return {
      showCommonFilters: false,
      docId: this.$route.params.docId,
      title: `${this.$t('Document Configurations')}`,
      rolePerm: ['document_documentconfig_list'],
      actionEnablePermission: ['document_documentconfig_enable'],
      actionDisablePermission: ['document_documentconfig_disable'],
      subtitle: '',
      intermediateBreadcrumbs: [{
        title: this.$t('Documents'),
        routerName: DOCUMENT_ROUTES.DOCUMENT_LIST
      }]
    }
  },
  computed: {
    ...mapGetters({
      ...DOCUMENT_CONFIG_TYPES.GENERIC.document.documentconfig.LIST.GETTERS,
      documentDetail: DOCUMENT_TYPES.GENERIC.document.document.DETAIL.GETTERS.detail
    }),
    showList () {
      return true
    },
    canSeeActionSelect () {
      return true
    },
    canAddInstance () {
      return hasPerm('document_documentconfig_create')
    }
  },
  methods: {
    ...mapMutations({
      setSelectedList: DOCUMENT_CONFIG_TYPES.GENERIC.document.documentconfig.LIST.MUTATIONS.setSellectedList,
      setSelectAll: DOCUMENT_CONFIG_TYPES.GENERIC.document.documentconfig.LIST.MUTATIONS.setSelectAll
    }),
    ...mapActions({
      ...DOCUMENT_CONFIG_TYPES.GENERIC.document.documentconfig.LIST.ACTIONS,
      getDocumentDetail: DOCUMENT_TYPES.GENERIC.document.document.DETAIL.ACTIONS.retrieve
    }),
    getComponentList () {
      if (this.docId) {
        this.getDocumentDetail({ id: this.docId }).then(() => {
          this.subtitle = this.documentDetail.name
        })
        this.setFilters({ filter__document_id: this.docId })
      }
    }
  }
}
</script>
