<template>
  <thux-table
    :instance="instance"
    :instance-name="instanceName"
    :order-by-dict="orderByDict"
    :goBack="goBack"
    :list="list"
    :fieldName="fieldName"
    :fields="fields"
    :fields-column="fieldsColumn"
    :select-all="selectAll"
    :selected-list="selectedList"
    :get-local-selected-all="getLocalSelectedAll"
    :local-selected-list="localSelectedList"
    @check-all="checkAll"
    @check-none="checkNone"
    @set-selected-row="setSelectedRow"
    :show-checks="showChecks"
    :showDetailButton="showDetailButton"
    @change-status="changeInstanceStatus"
    @sort-changed="orderTable"
    @remove-order-by="removeOrderFromTable"
    @open-detail-form="$emit('open-detail-form', $event)"
  >
    <template slot="date_start_end" slot-scope="row">
        <status-indicator :status="row.item.date_start_end"></status-indicator>
    </template>
    <template slot="is_downloadable" slot-scope="row">
        <status-indicator :status="row.item.is_downloadable"></status-indicator>
    </template>
    <template slot="is_optional" slot-scope="row">
        <status-indicator :status="row.item.is_optional"></status-indicator>
    </template>
    <template slot="any_organization" slot-scope="row">
        <status-indicator :status="row.item.any_organization"></status-indicator>
    </template>
    <template slot="owner_organization" slot-scope="row">
        <status-indicator :status="row.item.owner_organization"></status-indicator>
    </template>
    <template slot="any_yard" slot-scope="row">
        <status-indicator :status="row.item.any_yard"></status-indicator>
    </template>
  </thux-table>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as DOCUMENT_CONF_TYPES } from './store'
import { hasPerm } from '@/components/PermissionHelper'

import ThuxTableMixin from '../../../components/thux-table/ThuxTableMixin'
import ThuxTableChecksMixin from '../../../components/thux-table/thux-table-checks/ThuxTableChecksMixin'
import StatusIndicator from '@/components/StatusIndicator'

export default {
  name: 'DocumentConfigTable',
  mixins: [ThuxTableMixin, ThuxTableChecksMixin],
  components: {
    'status-indicator': StatusIndicator
  },
  data () {
    return {
      fields: [
        {
          key: 'checks',
          label: this.$t('Checks')
        },
        {
          key: 'status',
          label: this.$t('Status'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          sortable: true,
          value: 'status'
        },
        {
          key: 'description',
          label: this.$t('Description'),
          type: 'text',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          sortable: true,
          value: 'description'
        },
        {
          key: 'date_start_end',
          label: this.$t('Date start-end equired'),
          type: 'text',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          sortable: true,
          custom: true
        },
        {
          key: 'is_downloadable',
          label: this.$t('Is Downloadable'),
          type: 'text',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          sortable: true,
          custom: true
        },
        {
          key: 'is_optional',
          label: this.$t('Is Optional'),
          type: 'text',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          sortable: true,
          custom: true
        },
        {
          key: 'any_organization',
          label: this.$t('Any organization'),
          type: 'text',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          sortable: true,
          custom: true
        },
        {
          key: 'owner_organization',
          label: this.$t('Owner organization'),
          type: 'text',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          sortable: true,
          custom: true
        },
        {
          key: 'owner_forms',
          label: this.$t('Organization form'),
          type: 'text',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          sortable: false,
          value: 'owner_forms_names'
        },
        {
          key: 'owner_category',
          label: this.$t('Category'),
          type: 'text',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          sortable: false,
          value: 'owner_category_name'
        },
        {
          key: 'any_yard',
          label: this.$t('Any yard'),
          type: 'text',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          sortable: true,
          custom: true
        },
        {
          key: 'product__name',
          label: this.$t('Product'),
          type: 'text',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          sortable: true,
          value: 'product_name'
        },
        {
          key: 'action',
          label: this.$t('Actions'),
          tdClass: 'text-right text-nowrap',
          thClass: 'text-right text-nowrap'
        }
      ],
      fieldsColumn: ['status', 'description', 'date_start_end', 'is_downloadable', 'is_optional', 'any_organization', 'any_yard', 'product__name']
    }
  },
  computed: {
    ...mapGetters({
      selectAll: DOCUMENT_CONF_TYPES.GENERIC.document.documentconfig.LIST.GETTERS.selectAll,
      selectedList: DOCUMENT_CONF_TYPES.GENERIC.document.documentconfig.LIST.GETTERS.selectedList
    }),
    showChecks () {
      return true
    },
    showDetailButton () {
      return hasPerm('document_documentconfig_retrieve')
    }
  },
  methods: {
    ...mapMutations({
      setList: DOCUMENT_CONF_TYPES.GENERIC.document.documentconfig.LIST.MUTATIONS.setList,
      setSelectedList:
        DOCUMENT_CONF_TYPES.GENERIC.document.documentconfig.LIST.MUTATIONS.setSelectedList,
      setSelectAll: DOCUMENT_CONF_TYPES.GENERIC.document.documentconfig.LIST.MUTATIONS.setSelectAll
    }),
    ...mapActions({
      ...DOCUMENT_CONF_TYPES.GENERIC.document.documentconfig.LIST.ACTIONS
    })
  }
}
</script>
